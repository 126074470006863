import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 3.5,
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'center'
    },
    '&:after': {
      display: 'none'
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 12,
    height: 12,
    margin: 0,
    
  },
}))


export default function SwitchEdit({ onSwitcher, switcher, loading, text }) {

  const handleClick = async (e) => {
    
    if (loading) {
      return e.preventDefault()
    } else {
      await onSwitcher(switcher)
      return true
    }

  }

  return (
    <FormGroup className='block-switch'>
      <FormControlLabel
        onChange={handleClick}
        control={<Android12Switch />}
        label={text}
        labelPlacement='start'
        disabled={loading}
      />
    </FormGroup>
  );
}
