import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { CollapseMenu, CustomToolbar } from '../../components'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ConfigTable, AssignmentComponent, ConfigTableHighlight, ConfigurationSeparatePage } from './components'

import { backend_url } from '../../settings'
import { dateTimeFormatter, numberFormatter } from "../../utils/utils"
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";
import { TrendingUp } from '@mui/icons-material'

function Configuration() {

  const { t } = useTranslation(['translation', 'mars_dictionaries'])

  const location = useLocation().pathname.split("/").pop();
  const bu = localStorage.getItem('bu_bpx');
  const { prefsData } = useUserPrefs()

  const [configuration, setConfiguration] = useState({
    "COMPANY": null,
    "BUSINESS_UNIT": null,
    "CAL_ID": null,
    "SUBTOTAL_CODE": null,
    "AO_TYPE": null,
    "ALG_CODE": null
  });

  useEffect(() => {
    setConfiguration({ 'BUSINESS_UNIT': bu || '' });
  }, [])

  useEffect(() => { }, [location])

  const menuData = [
    {
      id: 1,
      name: t("Config.MENU.ENTARPRISE_SETTINGS"),
      children: [
        {
          id: 5,
          name: t("Config.MENU.COMPANY_CONFIGURATION"),
          link: "/configuration/company-configuration",
          children: []
        },
        {
          id: 6,
          name: t("Config.MENU.BUSINESS_UNIT_CONFIGURATION"),
          link: "/configuration/business-unit-configuration",
          children: []
        },
        {
          id: 27,
          name: t("Config.MENU.ENTERPRISE_IDENTIFICATION"),
          link: "/configuration/enterprise-identification",
          children: []
        },
      ]
    },
    {
      id: 3,
      name: t("Config.MENU.ACTUALS"),
      children: [
        {
          id: 9,
          name: t("Config.MENU.SELL_IN_PROCESSING"),
          link: "/configuration/sell-in-processing",
          children: []
        },
        // {
        //   id: 10,
        //   name: t("Config.MENU.SELL_OUT_PROCESSING"),
        //   link: "/configuration/sell-out-processing",
        //   children: []
        // },
      ]
    },
    {
      id: 4,
      name: t("Config.MENU.ACCRUAL"),
      children: [
        {
          id: 11,
          name: t("Config.MENU.ACCRUAL_OBJECT"),
          link: "/configuration/accrual-object",
          children: []
        },
        {
          id: 15,
          name: t("Config.MENU.ACCRUAL_ADJUSTMENT"),
          link: "/configuration/accrual-adjustment",
          children: []
        }
      ]
    },
    {
      id: 2,
      name: t("Config.MENU.CALENDARS"),
      children: [
        {
          id: 8,
          name: t("Config.MENU.CALENDAR_PERIODS"),
          link: "/configuration/calendar-periods",
          children: []
        },
        {
          id: 7,
          name: t("Config.MENU.CALENDAR_ASSIGNMENTS"),
          link: "/configuration/calendar-assignments",
          children: []
        },
      ]
    },
    {
      id: 21,
      name: t("Config.MENU.PAYMENT"),
      children: [
        {
          id: 22,
          name: t("Config.MENU.PAYMENT_PROCESSING"),
          link: "/configuration/payment",
          children: []
        },
      ]
    },
    {
      id: 23,
      name: t("Config.MENU.MASTER_DATA"),
      children: [
        {
          id: 24,
          name: t("Config.MENU.MATERIALS"),
          link: "/configuration/materials",
          children: []
        },
        {
          id: 25,
          name: t("Config.MENU.CUSTOMER_HIERARCHY"),
          link: "/configuration/customer_hierarchy",
          children: []
        }
      ]
    },
    {
      id: 16,
      name: t("Config.MENU.REPORTING"),
      children: [
        {
          id: 17,
          name: t("Config.MENU.HIGHLIGHTS"),
          link: "/configuration/highlights",
          children: []
        },
        {
          id: 26,
          name: t("Config.MENU.TRADE_EXPENDITURE_MONITOR"),
          link: "/configuration/trade-expenditure-monitor",
          children: []
        },
      ]
    },
    {
      id: 19,
      name: t("Config.MENU.APPROVAL_WORKFLOW"),
      children: [
        {
          id: 20,
          name: t("Config.MENU.APPROVAL_WORKFLOW_RULES"),
          link: "/configuration/approval-workflow",
          children: []
        }
      ]
    },
    {
      id: 12,
      name: t("Config.MENU.TECHNICAL_CONFIG"),
      children: [
        {
          id: 13,
          name: t("Config.MENU.ALGORITHMS"),
          link: "/configuration/algorithms",
          children: []
        },
        {
          id: 14,
          name: t("Config.MENU.UNIT_OF_MEASURE"),
          link: "/configuration/unit-of-measure",
          children: []
        },
        {
          id: 18,
          name: t("Config.MENU.INTEGRATION_PARAMETERS"),
          link: "/configuration/integration-parameters",
          children: []
        }
      ]
    },
  ]

  const currentBread = [
    { label: t("Config.MENU.COMPANY_CONFIGURATION"), link: 'company-configuration' },
    { label: t("Config.MENU.BUSINESS_UNIT_CONFIGURATION"), link: 'business-unit-configuration' },
    { label: t("Config.MENU.CALENDAR_ASSIGNMENTS"), link: 'calendar-assignments' },
    { label: t("Config.MENU.CALENDAR_PERIODS"), link: 'calendar-periods' },
    { label: t("Config.MENU.SELL_IN_PROCESSING"), link: 'sell-in-processing' },
    // { label: t("Config.MENU.SELL_OUT_PROCESSING"), link: 'sell-out-processing' },
    { label: t("Config.MENU.ACCRUAL_OBJECT"), link: 'accrual-object' },
    { label: t("Config.MENU.ACCRUAL_ADJUSTMENT"), link: 'accrual-adjustment' },
    { label: t("Config.MENU.ALGORITHMS"), link: 'algorithms' },
    { label: t("Config.MENU.UNIT_OF_MEASURE"), link: 'unit-of-measure' },
    { label: t("Config.MENU.INTEGRATION_PARAMETERS"), link: 'integration-parameters' },
    { label: t("Config.MENU.HIGHLIGHTS"), link: 'highlights' },
    { label: t("Config.MENU.TRADE_EXPENDITURE_MONITOR"), link: 'trade-expenditure-monitor' },
    { label: t("Config.MENU.APPROVAL_WORKFLOW_RULES"), link: 'approval-workflow' },
    { label: t("Config.MENU.PAYMENT_PROCESSING"), link: 'payment' },
    { label: t("Config.MENU.MATERIALS"), link: 'materials' },
    { label: t("Config.MENU.CUSTOMER_HIERARCHY"), link: 'customer_hierarchy' },
    { label: t("Config.MENU.ENTERPRISE_IDENTIFICATION"), link: 'enterprise-identification' }
  ]

  const displayCurrentBread = currentBread.map(item => {
    if (location === item.link) {
      return item
    } else {
      return null
    }
  })

  const arrConfigTables = [
    {
      page: "company-configuration",
      children: [
        {
          id: "14",
          type: 'table',
          url: backend_url.config_companies,
          selected: true,
          endpoint: [
            { field: "COMPANY_CODE" },
          ],
          titleTable: t("Config.TITLE.COMPANY_DEFINITION"),
          focusTable: "COMPANY",
          select: "COMPANY_CODE",
          value: "COMPANY",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [],
          configurationDepend: [],
          columnsTable: [
            {
              field: 'COMPANY_CODE',
              headerName: t("Config.COMPANY_DEFINITION.COMPANY_CODE"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'COMPANY_NAME',
              headerName: t("Config.COMPANY_DEFINITION.COMPANY_NAME"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'EXT_COMPANY_CODE',
              headerName: t("Config.COMPANY_DEFINITION.EXT_COMPANY_CODE"),
              flex: 1,
              minWidth: 150,
            },
          ]
        },
        {
          id: "1",
          type: 'table',
          url: backend_url.config_company_posting_rules,
          selected: false,
          endpoint: [
            { field: "COMPANY" },
            { field: "DATE_VALID_TO" }
          ],
          titleTable: t("Config.TITLE.COMPANY_CONFIG"),
          focusTable: "COMPANY",
          select: "COMPANY",
          value: "COMPANY",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            {
              field: 'COMPANY',
              url: backend_url.config_companies,
              code: "COMPANY_CODE",
            },
            { field: 'ALG_CODE', url: backend_url.config_algorithms, code: "ALG_CODE" },
          ],
          configurationDepend: ['COMPANY'],
          columnsTable: [
            {
              field: 'COMPANY',
              headerName: t("Config.COMPANY_CONFIG.COMPANY"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 150,
            },
            {
              field: 'ACCOUNT_GROUP',
              headerName: t("Config.COMPANY_CONFIG.ACCOUNT_GROUP"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'PRODUCT_LEVEL',
              headerName: t("Config.COMPANY_CONFIG.PRODUCT_LEVEL"),
              type: 'singleSelect',
              valueOptions: ['FERT', 'ZREP'],
              required: true,
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'ALG_CODE',
              headerName: t("Config.COMPANY_CONFIG.ALG_CODE"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 150,
            },
            {
              field: 'INTERFACE_PROFILE',
              headerName: t("Config.COMPANY_CONFIG.INTERFACE_PROFILE"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'APP_NAME',
              headerName: t("Config.COMPANY_CONFIG.APP_NAME"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'SYSTEM',
              headerName: t("Config.COMPANY_CONFIG.SYSTEM"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'DATE_VALID_TO',
              headerName: t("Config.COMPANY_CONFIG.DATE_VALID_TO"),
              flex: 1,
              type: 'date',
              required: true,
              minWidth: 150,
              isDefaultValue: true,
              defaultValue: moment('9999-12-30').format("YYYY-MM-DD"),
              renderCell: ({ value }) => {
                if (value) {
                  return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
                }
                return value
              },
              valueParser: (value) => {
                if (value) {
                  return moment(value).format("YYYY-MM-DD")
                }
              }
            },
          ]
        },
      ]
    },
    {
      page: "business-unit-configuration",
      children: [
        {
          id: "3",
          type: 'table',
          url: backend_url.config_business_units,
          selected: false,
          titleTable: t("Config.TITLE.CONFIG_BUSINESS_UNITS"),
          endpoint: [
            { field: "BUSINESS_UNIT" }
          ],
          focusTable: "BUSINESS_UNIT",
          select: "BUSINESS_UNIT",
          value: "BUSINESS_UNIT",
          idReplace: "",
          //notistack: false,
          notistack: true,
          configurationDepend: [],
          columnSingleSelect: [
            {
              field: 'COMPANY_CODE',
              url: backend_url.config_companies,
              code: "COMPANY_CODE",
            },
          ],
          excludeColumn: ['GRAPHICAL_FILE_TYPE', 'GRAPHICAL_FILE_TYPE_DARK', 'LOGO', 'LOGO_DARK', 'LOGO_FILENAME','LOGO_FILENAME_DARK'],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.CONFIG_BUSINESS_UNITS.BUSINESS_UNIT"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'DESCRIPTION',
              headerName: t("Config.CONFIG_BUSINESS_UNITS.DESCRIPTION"),
              flex: 1,
              required: true,
              minWidth: 180,
            },
            {
              field: 'COMPANY_CODE',
              headerName: t("Config.CONFIG_BUSINESS_UNITS.COMPANY_CODE"),
              flex: 1,
              type: 'singleSelect',
              minWidth: 150,
            },
            {
              field: 'APPROVAL_EXPIRATION_DAYS',
              headerName: t("Config.CONFIG_BUSINESS_UNITS.APPROVAL_EXPIRATION_DAYS"),
              flex: 1,
              type: 'number',
              minWidth: 150,
            },
            {
              field: 'DEFAULT_SALES_AREA',
              headerName: t("Config.CONFIG_BUSINESS_UNITS.DEFAULT_SALES_AREA"),
              flex: 1,
              minWidth: 180,
            },
            {
              field: 'BP_MASK',
              headerName: t("Config.CONFIG_BUSINESS_UNITS.BP_MASK"),
              flex: 1,
              minWidth: 180,
              type: 'autoComplete',
              isAllOption: true,
              multiple: true,
              valueParser: (value) => {
                if(value.length&&value[0].type==='all'){
                  return '*'
                }
                return value.map(it => it.BUSINESS_PARTNER).join('|')
              },
              valueFormatted: (value) => {
                if(value[0]==='*'){
                  return ['all']
                }
                return value.split('|')
              },
            },
            {
              field: 'GL_ACCOUNT_DETERMINATION',
              headerName: t("Config.CONFIG_BUSINESS_UNITS.GL_ACCOUNT_DETERMINATION"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ["AOType_ExtLineType_ExpGL", "COPY_ExtLineType"],
              minWidth: 180,
            }
          ]
        },
        {
          id: "2",
          type: 'table',
          url: backend_url.config_business_segments,
          selected: false,
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "SALES_AREA" },
            { field: "BUSINESS_SEGMENT" },
          ],
          titleTable: t("Config.TITLE.CONFIG_BUSINESS_SEGMENTS"),
          focusTable: "BUSINESS_UNIT",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
          ],
          endpointForGet: [
            { field: 'business_units', name: 'bu_bpx' }
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'BUSINESS_UNIT' }
          ],
          configurationDepend: [],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.CONFIG_BUSINESS_SEGMENTS.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'BUSINESS_SEGMENT',
              headerName: t("Config.CONFIG_BUSINESS_SEGMENTS.BUSINESS_SEGMENT"),
              flex: 1,
              required: true,
              minWidth: 230,
              type: 'singleSelect',
              renderCell: (params) => {
                return `${params.row.BUSINESS_SEGMENT}: ${t("BUSINESS_SEGMENT", { ns: 'mars_dictionaries' })[params.row.BUSINESS_SEGMENT]}`
              },
              valueOptions: Object.entries(t("BUSINESS_SEGMENT", { ns: 'mars_dictionaries' }))
                .map(it => ({ value: it[0], option: `${it[0]}:  ${it[1]}` })),
            },
            {
              field: 'SALES_AREA',
              headerName: t("Config.CONFIG_BUSINESS_SEGMENTS.SALES_AREA"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
          ]
        }
      ]
    },
    {
      page: "calendar-assignments",
      children: [
        {
          id: "5",
          type: 'table',
          url: backend_url.config_calendars_assignment,
          urlSelected: backend_url.config_calendars,
          selected: false,
          isSelectedComponent: true,
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "CAL_ID" },
            { line: 'TYPE_ASSIGNMENT' },
            { line: 'VALUE_ASSIGNMENT' },
            { line: 'DATE_VALID_TO' }
          ],
          endpointForGet: [
            { field: 'BUSINESS_UNIT', name: 'bu_bpx' }
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'BUSINESS_UNIT' }
          ],
          titleTable: t("Config.TITLE.CONFIG_CALENDAR_ASSIGNMENTS"),
          focusTable: "BUSINESS_UNIT",
          select: 'CAL_ID',
          value: 'CAL_ID',
          idReplace: "",
          //notistack: false,
          notistack: true,
          columnDefined: ['BUSINESS_UNIT', 'CAL_ID'],
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
            { field: 'CAL_ID', url: backend_url.config_calendars, code: "CAL_ID" },
            {
              field: 'VALUE_ASSIGNMENT',
              url: backend_url.config_accrual_object_type,
              code: 'AO_TYPE',
            }
          ],
          configurationDepend: ['CAL_ID'],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.BUSINESS_UNIT"),
              type: 'singleSelect',
              required: true,
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'CAL_ID',
              headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.CAL_ID"),
              type: 'singleSelect',
              required: true,
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'TYPE_ASSIGNMENT',
              headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.TYPE_ASSIGNMENT"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ["*", "Business partner", "AO Type", "AO Type and Business Partner"],
              required: true,
              minWidth: 180,
              optionsParser: (value) => {
                if (value === 'Business partner') {
                  return {
                    field: 'VALUE_ASSIGNMENT',
                    valueRow: {
                      field: 'VALUE_ASSIGNMENT',
                      headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.BUSINESS_PARTNER"),
                      flex: 1,
                      minWidth: 180,
                      type: 'autoComplete',
                      defaultType: 'empty',
                      multiple: false,
                      required: true,
                      valueParser: (value) => {
                        if(value&&value.BUSINESS_PARTNER){
                          return value.BUSINESS_PARTNER
                        }
                        return value
                      },
                      valueFormatted: (value) => {
                        if(value&&value.BUSINESS_PARTNER){
                          return [value.BUSINESS_PARTNER]
                        }
                        return [value]
                      },
                    },
                  };
                } else if (value === 'AO Type') {
                  return {
                    field: 'VALUE_ASSIGNMENT',
                    valueRow: {
                      field: 'VALUE_ASSIGNMENT',
                      headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.VALUE_ASSIGNMENT"),
                      flex: 1,
                      defaultType: 'empty',
                      type: 'singleSelect',
                      required: true,
                      minWidth: 200,
                    },
                  };
                } else if (value === 'AO Type and Business Partner') {
                  return {
                    field: 'VALUE_ASSIGNMENT',
                    valueRow: {
                      field: 'VALUE_ASSIGNMENT',
                      headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.VALUE_ASSIGNMENT"),
                      flex: 1,
                      minWidth: 180,
                      defaultType: 'empty',
                      type: 'array',
                      multiple: false,
                      components: [
                        {
                          field: 'VALUE_ASSIGNMENT',
                          headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.VALUE_ASSIGNMENT"),
                          flex: 1,
                          type: 'singleSelect',
                          required: true,
                          minWidth: 200,
                        },
                        {
                          field: 'VALUE_ASSIGNMENT',
                          headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.BUSINESS_PARTNER"),
                          flex: 1,
                          minWidth: 180,
                          type: 'autoComplete',
                          multiple: false,
                          required: true,
                          valueParser: (value) => {
                            return value.BUSINESS_PARTNER
                          },
                          valueFormatted: (value) => {
                            return [value]
                          },
                        }
                      ],
                      valueParser: (value, ind) => {
                        if (value) {
                          return value.split(';')[ind]
                        }
                        return ''
                      },
                      valueFormatted: (state, value, ind) => {
                        const array = state ? state.split(';') : ['', ''];
                        array[ind] = value;
                        return array.join(';');
                      }
                    },
                  }
                } else {
                  return {
                    field: 'VALUE_ASSIGNMENT',
                    valueRow: {
                      field: 'VALUE_ASSIGNMENT',
                      headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.VALUE_ASSIGNMENT"),
                      flex: 1,
                      minWidth: 180,
                      type: 'empty',
                      defaultValue: ' ',
                      multiple: false,
                    },
                  }
                }
              }
            },
            {
              field: 'DATE_VALID_TO',
              headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.DATE_VALID_TO"),
              flex: 1,
              type: 'date',
              required: true,
              minWidth: 150,
              isDefaultValue: true,
              defaultValue: moment(new Date()).format("YYYY-MM-DD"),
              renderCell: ({ value }) => {
                if (value) {
                  return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
                }
                return value
              },
              valueParser: (value) => {
                if (value) {
                  return moment(value).format("YYYY-MM-DD")
                }
              }
            },
            {
              field: 'VALUE_ASSIGNMENT',
              headerName: t("Config.CONFIG_CALENDAR_ASSIGNMENTS.VALUE_ASSIGNMENT"),
              flex: 1,
              type: 'empty',
              minWidth: 200,
              valueParser: (value) => {
                return value
              },
              valueFormatted: (value) => {
                return [value]
              }
            },
          ]
        },
      ]
    },
    {
      page: "calendar-periods",
      children: [
        {
          id: "4",
          type: 'table',
          url: backend_url.config_calendars,
          selected: true,
          select: "CAL_ID",
          value: 'CAL_ID',
          endpoint: [
            { field: "CAL_ID" }
          ],
          titleTable: t("Config.TITLE.CONFIG_CALENDARS"),
          focusTable: "CAL_ID",
          idReplace: "",
          notistack: true,
          configurationDepend: [],
          columnsTable: [
            {
              field: 'CAL_ID',
              headerName: t("Config.CONFIG_CALENDARS.CAL_ID"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
            {
              field: 'CAL_DESCRIPTION',
              headerName: t("Config.CONFIG_CALENDARS.CAL_DESCRIPTION"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
          ]
        },
        {
          id: "6",
          type: 'table',
          url: backend_url.config_calendar_periods,
          selected: false,
          endpoint: [
            { field: "CAL_ID" },
            { field: "PERIOD_ID" },
            { field: "PERIOD_TYPE" }
          ],
          titleTable: t("Config.TITLE.CALENDAR_PERIODS"),
          focusTable: "CAL_ID",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            {
              field: 'CAL_ID',
              url: backend_url.config_calendars,
              code: "CAL_ID",
            },
          ],
          configurationDepend: ["CAL_ID"],
          columnsTable: [
            {
              field: 'CAL_ID',
              headerName: t("Config.CALENDAR_PERIODS.CAL_ID"),
              type: 'singleSelect',
              required: true,
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'PERIOD_ID',
              headerName: t("Config.CALENDAR_PERIODS.PERIOD_ID"),
              flex: 1,
              required: true,
              minWidth: 170,
            },
            {
              field: 'DATE_PERIOD_FROM',
              headerName: t("Config.CALENDAR_PERIODS.DATE_PERIOD_FROM"),
              flex: 1,
              type: 'date',
              minWidth: 170,
              required: true,
              isDefaultValue: true,
              defaultValue: moment(new Date()).format("YYYY-MM-DD"),
              renderCell: ({ value }) => {
                if (value) {
                  return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
                }
                return value
              },
              valueParser: (value) => {
                if (value) {
                  return moment(value).format("YYYY-MM-DD")
                }
              }
            },
            {
              field: 'DATE_PERIOD_TO',
              headerName: t("Config.CALENDAR_PERIODS.DATE_PERIOD_TO"),
              flex: 1,
              type: 'date',
              minWidth: 170,
              required: true,
              isDefaultValue: true,
              defaultValue: moment(new Date()).format("YYYY-MM-DD"),
              renderCell: ({ value }) => {
                if (value) {
                  return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
                }
                return value
              },
              valueParser: (value) => {
                if (value) {
                  return moment(value).format("YYYY-MM-DD")
                }
              }
            },
            {
              field: 'PERIOD_TYPE',
              headerName: t("Config.CALENDAR_PERIODS.PERIOD_TYPE"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              valueOptions: ['W', 'M', 'P'],
              minWidth: 170,
            },

          ]
        },
        {
          id: "7",
          type: 'table',
          url: backend_url.config_calendars_events,
          selected: false,
          endpoint: [
            { field: "CAL_ID" },
            { field: "DATE_EVENT" },
          ],
          titleTable: t("Config.TITLE.CALENDAR_EVENTS"),
          focusTable: "CAL_ID",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            {
              field: 'CAL_ID',
              url: backend_url.config_calendars,
              code: "CAL_ID",
            },
          ],
          configurationDepend: ["CAL_ID"],
          columnsTable: [
            {
              field: 'CAL_ID',
              headerName: t("Config.CALENDAR_EVENTS.CAL_ID"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 150,
            },
            {
              field: 'DATE_EVENT',
              headerName: t("Config.CALENDAR_EVENTS.DATE_EVENT"),
              flex: 1,
              type: 'date',
              required: true,
              minWidth: 150,
              isDefaultValue: true,
              defaultValue: moment(new Date()).format("YYYY-MM-DD"),
              renderCell: ({ value }) => {
                if (value) {
                  return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
                }
                return value
              },
              valueParser: (value) => {
                if (value) {
                  return moment(value).format("YYYY-MM-DD")
                }
              }
            },
            {
              field: 'DATE_POSTING_OVERWRITE',
              headerName: t("Config.CALENDAR_EVENTS.DATE_POSTING_OVERWRITE"),
              flex: 1,
              type: 'date',
              minWidth: 200,
              isDefaultValue: true,
              defaultValue: null,
              renderCell: ({ value }) => {
                if (value) {
                  return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
                }
                return value
              },
              valueParser: (value) => {
                if (value) {
                  return moment(value).format("YYYY-MM-DD")
                }
                return null
              }
            },
            {
              field: 'HOLIDAY',
              headerName: t("Config.CALENDAR_EVENTS.HOLIDAY"),
              flex: 1,
              type: 'boolean',
              minWidth: 100,
              isDefaultValue: true,
              defaultValue: null,
            },
            {
              field: 'EXCLUDED',
              headerName: t("Config.CALENDAR_EVENTS.EXCLUDED"),
              flex: 1,
              type: 'boolean',
              minWidth: 100,
              isDefaultValue: true,
              defaultValue: null,
            },
            {
              field: 'NO_ACCRUAL_DAY',
              headerName: t("Config.CALENDAR_EVENTS.NO_ACCRUAL_DAY"),
              flex: 1,
              type: 'boolean',
              minWidth: 100,
            },
          ]
        },
      ]
    },
    {
      page: 'payment',
      children: [
        {
          id: "22",
          type: 'table',
          url: backend_url.config_payment_processing,
          endpoint: [
            { field: "COMPANY" },
            { line: "BUSINESS_SEGMENT" },
            { line: "SALES_AREA" },
            { value: "BUSINESS_UNIT", name: 'business_unit' }
          ],
          titleTable: t("Config.TITLE.PAYMENT_PROCESSING"),
          idReplace: "",
          selected: false,
          notistack: true,
          columnSingleSelect: [
            {
              field: 'COMPANY',
              url: backend_url.config_companies,
              code: "COMPANY_CODE",
            },
            {
              field: 'BUSINESS_UNIT',
              url: backend_url.config_business_units,
              code: "BUSINESS_UNIT"
            },
          ],
          configurationDepend: [],
          endpointForGet: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'BUSINESS_UNIT' }
          ],
          columnsTable: [
            {
              field: 'COMPANY',
              headerName: t("Config.PAYMENT_PROCESSING.COMPANY"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.PAYMENT_PROCESSING.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'SALES_AREA',
              headerName: t("Config.PAYMENT_PROCESSING.SALES_AREA"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
            {
              field: 'BUSINESS_SEGMENT',
              headerName: t("Config.PAYMENT_PROCESSING.BUSINESS_SEGMENT"),
              flex: 1,
              required: true,
              minWidth: 230,
              type: 'singleSelect',
              renderCell: (params) => {
                return `${params.row.BUSINESS_SEGMENT}: ${t("BUSINESS_SEGMENT", { ns: 'mars_dictionaries' })[params.row.BUSINESS_SEGMENT]}`
              },
              valueOptions: Object.entries(t("BUSINESS_SEGMENT", { ns: 'mars_dictionaries' }))
                .map(it => ({ value: it[0], option: `${it[0]}:  ${it[1]}` })),
            },
            {
              field: 'AO_ID_DETERMINATION',
              headerName: t("Config.PAYMENT_PROCESSING.AO_ID_DETERMINATION"),
              flex: 1,
              required: true,
              type: 'singleSelect',
              valueOptions: ['ACTIVITY_CHARACTERISTICS', 'EXT_REFERENCE', 'ACTIVITY_CHARACTERISTICS_THEN_EXT_REF', 'EXT_REF_THEN_ACTIVITY_CHARACTERISTICS'],
              minWidth: 230,
            },
            {
              field: 'AO_LINE_DETERMINATION',
              headerName: t("Config.PAYMENT_PROCESSING.AO_LINE_DETERMINATION"),
              flex: 1,
              required: true,
              type: 'singleSelect',
              valueOptions: ['ONE_LINE_PER_DOCUMENT', 'EXT_LINE_REFERENCE'],
              minWidth: 230,
            },
            {
              field: 'CALCULATION_BASE',
              headerName: t("Config.PAYMENT_PROCESSING.CALCULATION_BASE"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
            {
              field: 'INTEGRATION_MODE',
              headerName: t("Config.PAYMENT_PROCESSING.INTEGRATION_MODE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['REQUEST', 'CONFIRMATION', 'REQUEST_CONFIRMATION'],
              required: true,
              minWidth: 230,
            },
            {
              field: 'AO_STATUS_CLOSED',
              headerName: t("Config.PAYMENT_PROCESSING.AO_STATUS_CLOSED"),
              flex: 1,
              required: true,
              type: 'singleSelect',
              valueOptions: ['REJECT', 'SAVE_ONLY'],
              minWidth: 230,
            },
          ]
        },
      ]
    },
    {
      page: 'materials',
      children: [
        {
          id: "24",
          type: 'table',
          url: backend_url.config_material_attribute,
          selected: false,
          endpoint: [
            { field: "ATTR_GROUP" },
            { field: "ATTR_NAME" },
            { field: "BUSINESS_UNIT" },
            { field: "KEY" },
            { field: "ZSEGMENT" },
          ],
          titleTable: t("Config.TITLE.MATERIAL_ATTRIBUTES"),
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
          ],
          configurationDepend: [],
          endpointForGet: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'BUSINESS_UNIT' }
          ],
          parserForPost: (rows, data) => {
            const SEQUENCE = Math.max(...rows.map(it => it.SEQUENCE)) + 1;
            return { ...data, SEQUENCE }
          },
          parserForGet: (data) => data,
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.MATERIAL_ATTRIBUTES.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 150,
            },
            {
              field: 'ZSEGMENT',
              headerName: t("Config.MATERIAL_ATTRIBUTES.ZSEGMENT"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'KEY',
              headerName: t("Config.MATERIAL_ATTRIBUTES.KEY"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'ATTR_GROUP',
              headerName: t("Config.MATERIAL_ATTRIBUTES.ATTR_GROUP"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'ATTR_NAME',
              headerName: t("Config.MATERIAL_ATTRIBUTES.ATTR_NAME"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'ATTR_SOURCE',
              headerName: t("Config.MATERIAL_ATTRIBUTES.ATTR_SOURCE"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'IN_LIST_VIEW',
              headerName: t("Config.MATERIAL_ATTRIBUTES.IN_LIST_VIEW"),
              flex: 1,
              required: true,
              minWidth: 150,
              type: 'boolean',
              isDefaultValue: true,
              defaultValue: false,
            },
          ]
        },
        {
          id: "23",
          type: 'table',
          url: backend_url.config_material_statuses,
          selected: false,
          endpoint: [
            { field: "BUSINESS_UNIT" },
          ],
          titleTable: t("Config.TITLE.MATERIAL_STATUSES"),
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
          ],
          configurationDepend: [],
          endpointForGet: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'BUSINESS_UNIT' }
          ],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.MATERIAL_STATUSES.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 150,
            },
            {
              field: 'SALES_BLOCKED_MATERIAL_STATUSES',
              headerName: t("Config.MATERIAL_STATUSES.SALES_BLOCKED_MATERIAL_STATUSES"),
              flex: 1,
              required: true,
              minWidth: 150,
              valueParser: (value) => {
                if (value) {
                  return value.split(',')
                }
                return []
              },
              valueFormatted: (value) => {
                if (value) {
                  return value.join(',')
                }
                return value
              },
            },
            {
              field: 'PLANT_BLOCKED_MATERIAL_STATUSES',
              headerName: t("Config.MATERIAL_STATUSES.PLANT_BLOCKED_MATERIAL_STATUSES"),
              flex: 1,
              minWidth: 150,
              valueParser: (value) => {
                if (value) {
                  return value.split(',')
                }
                return null
              },
              valueFormatted: (value) => {
                if (value) {
                  return value.join(',')
                }
                return value
              },
            },
            {
              field: 'POSTING_BLOCK_CUSTOMER_STATUSES',
              headerName: t("Config.MATERIAL_STATUSES.POSTING_BLOCK_CUSTOMER_STATUSES"),
              flex: 1,
              minWidth: 150,
              valueParser: (value) => {
                if (value) {
                  return value.split(',')
                }
                return null
              },
              valueFormatted: (value) => {
                if (value) {
                  return value.join(',')
                }
                return value
              },
            },
            {
              field: 'CLOSING_MATERIAL_STATUSES',
              headerName: t("Config.MATERIAL_STATUSES.CLOSING_MATERIAL_STATUSES"),
              flex: 1,
              minWidth: 150,
              valueParser: (value) => {
                if (value) {
                  return value.split(',')
                }
                return null
              },
              valueFormatted: (value) => {
                if (value) {
                  return value.join(',')
                }
                return value
              },
            },
          ]
        },
      ]
    },
    {
      page: 'customer_hierarchy',
      children: [
        {
          id: "25",
          type: 'table',
          url: backend_url.config_customer_hierarchy_ignore_range,
          selected: false,
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "id" },
          ],
          titleTable: t("Config.TITLE.CUSTOMER_HIERARCHY_IGNORE_RANGE"),
          idReplace: false,
          notistack: true,
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
          ],
          configurationDepend: [],
          endpointForGet: [
            { field: 'business_units', name: 'bu_bpx' }
          ],
          endpointForPost: [
            { field: 'business_units', name: 'BUSINESS_UNIT' }
          ],
          parserForPost: (rows, data) => {
            const id = Math.max(...rows.map(it => it.id)) + 1;
            return { ...data, id }
          },
          parserForGet: (data) => data,
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.CUSTOMER_HIERARCHY_IGNORE_RANGE.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 150,
            },
            {
              field: 'RANGE_MIN',
              headerName: t("Config.CUSTOMER_HIERARCHY_IGNORE_RANGE.RANGE_MIN"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'RANGE_MAX',
              headerName: t("Config.CUSTOMER_HIERARCHY_IGNORE_RANGE.RANGE_MAX"),
              flex: 1,
              required: true,
              minWidth: 150,
            },

          ]
        },
      ]
    },
    {
      page: "sell-in-processing",
      children: [
        {
          id: "9",
          type: 'table',
          url: backend_url.config_subtotals,
          selected: true,
          select: 'SUBTOTAL_CODE',
          value: 'SUBTOTAL_CODE',
          endpoint: [
            { field: "SUBTOTAL_CODE" },
          ],
          columnSingleSelect: [
            { field: 'ALG_CODE', url: backend_url.config_algorithms, code: "ALG_CODE" },
          ],
          titleTable: t("Config.TITLE.SUBTOTAL_DEFINITION"),
          focusTable: "SUBTOTAL_CODE",
          idReplace: "",
          notistack: true,
          configurationDepend: [],

          columnsTable: [
            {
              field: 'SUBTOTAL_CODE',
              headerName: t("Config.ASSIGNMENT_SUBTOTAL_BUSINESS_UNIT.SUBTOTAL_CODE"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
            {
              field: 'SUBTOTAL_NAME',
              headerName: t("Config.ASSIGNMENT_SUBTOTAL_BUSINESS_UNIT.SUBTOTAL_NAME"),
              required: true,
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'ALG_CODE',
              headerName: t("Config.ASSIGNMENT_SUBTOTAL_BUSINESS_UNIT.ALG_CODE"),
              flex: 1,
              type: 'singleSelect',
              minWidth: 230,
            },
            {
              field: 'CONFIG_PAR1',
              headerName: t("Config.ASSIGNMENT_SUBTOTAL_BUSINESS_UNIT.CONFIG_PAR1"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
            {
              field: 'CONFIG_PAR2',
              headerName: t("Config.ASSIGNMENT_SUBTOTAL_BUSINESS_UNIT.CONFIG_PAR2"),
              flex: 1,
              required: true,
              type: 'singleSelect',
              valueOptions: ['FKDAT', 'PRSDT'],
              minWidth: 230,
            },
            {
              field: 'CONFIG_PAR3',
              headerName: t("Config.ASSIGNMENT_SUBTOTAL_BUSINESS_UNIT.CONFIG_PAR3"),
              flex: 1,
              required: true,
              type: 'singleSelect',
              valueOptions: ['MATNR', 'PMATN'],
              minWidth: 230,
            },
            {
              field: 'CONFIG_PAR4',
              headerName: t("Config.ASSIGNMENT_SUBTOTAL_BUSINESS_UNIT.CONFIG_PAR4"),
              flex: 1,
              required: true,
              type: 'singleSelect',
              valueOptions: ['KUNAG', 'KUNWE'],
              minWidth: 230,
            },
          ]
        },
        {
          id: "8",
          type: 'assigment',
          url: backend_url.config_subtotal_assignment,
          selected: false,
          urlSelected: backend_url.config_subtotals,
          value: 'SUBTOTAL_CODE',
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "SUBTOTAL_CODE" },
          ],
          titleTable: t("Config.TITLE.ASSIGNMENT_SUBTOTAL_BUSINESS_UNIT"),
          focusTable: "BUSINESS_UNIT",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
            { field: 'SUBTOTAL_CODE', url: backend_url.config_subtotals, code: "SUBTOTAL_CODE" },
          ],
          endpointForGet: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          configurationDepend: ["SUBTOTAL_CODE"],
          columnDefined: ['BUSINESS_UNIT', 'SUBTOTAL_CODE'],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.SUBTOTAL_DEFINITION.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'SUBTOTAL_CODE',
              headerName: t("Config.SUBTOTAL_DEFINITION.SUBTOTAL_CODE"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
          ]
        },
      ]
    },
    {
      page: "accrual-object",
      children: [
        {
          id: "10",
          type: 'table',
          url: backend_url.config_accrual_object_type,
          endpoint: [
            { field: "AO_TYPE" },
          ],
          titleTable: t("Config.TITLE.AO_TYPE_DEFINITION"),
          focusTable: "AO_TYPE",
          idReplace: "",
          selected: true,
          select: 'AO_TYPE',
          value: 'AO_TYPE',
          notistack: true,
          columnSingleSelect: [
            { field: 'ALG_CODE', url: backend_url.config_algorithms, code: "ALG_CODE" },
          ],
          configurationDepend: [],
          endpointForGet: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          columnsTable: [
            {
              field: 'AO_TYPE',
              headerName: t("Config.AO_TYPE_DEFINITION.AO_TYPE"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
            {
              field: 'TYPE_DESCRIPTION',
              headerName: t("Config.AO_TYPE_DEFINITION.TYPE_DESCRIPTION"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
            {
              field: 'MECHANIC',
              headerName: t("Config.AO_TYPE_DEFINITION.MECHANIC"),
              flex: 1,
              minWidth: 230,
              required: true,
            },
            {
              field: 'ALG_CODE',
              headerName: t("Config.AO_TYPE_DEFINITION.ALG_CODE"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'IS_MANUAL',
              headerName: t("Config.AO_TYPE_DEFINITION.IS_MANUAL"),
              flex: 1,
              type: 'boolean',
              isDefaultValue: true,
              defaultValue: false,
              minWidth: 230,
            },
            {
              field: 'IS_INACTIVE',
              headerName: t("Config.AO_TYPE_DEFINITION.IS_INACTIVE"),
              flex: 1,
              type: 'boolean',
              isDefaultValue: true,
              defaultValue: false,
              minWidth: 230,
            },
          ]
        },
        {
          id: "20",
          type: 'table',
          url: backend_url.config_gl_assignment,
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "AO_TYPE" },
            { field: "EXT_LINE_TYPE" },
            { field: "DATE_VALID_TO" },
            { value: 'AO_TYPE', name: 'ao_type' }
          ],
          titleTable: t("Config.TITLE.ASSIGNMENT_TO_GL_ACCOUNTS"),
          focusTable: "AO_TYPE",
          idReplace: "",
          selected: false,
          select: 'AO_TYPE',
          value: 'AO_TYPE',
          notistack: true,
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
            { field: 'AO_TYPE', url: backend_url.config_accrual_object_type, code: "AO_TYPE" },
          ],
          configurationDepend: [{ field: 'AO_TYPE', name: 'ao_types' }],
          endpointForGet: [
            { field: 'business_units', name: 'bu_bpx' }
          ],
          endpointForPost: [
            { field: 'business_units', name: 'BUSINESS_UNIT' }
          ],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.ASSIGNMENT_TO_GL_ACCOUNTS.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'AO_TYPE',
              headerName: t("Config.ASSIGNMENT_TO_GL_ACCOUNTS.AO_TYPE"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'EXT_LINE_TYPE',
              headerName: t("Config.ASSIGNMENT_TO_GL_ACCOUNTS.EXT_LINE_TYPE"),
              flex: 1,
              required: true,
              minWidth: 150,
            },
            {
              field: 'EXPENSE_GL',
              headerName: t("Config.ASSIGNMENT_TO_GL_ACCOUNTS.EXPENSE_GL"),
              flex: 1,
              required: true,
              type: 'number',
              minWidth: 150,
              renderCell: ({ value }) => value
            },
            // {
            //   field: 'BALANCE_GL',
            //   headerName: t("Config.ASSIGNMENT_TO_GL_ACCOUNTS.BALANCE_GL"), 
            //   flex: 1,
            //   minWidth: 230,
            //   hide: true
            // },
            {
              field: 'DATE_VALID_TO',
              headerName: t("Config.ASSIGNMENT_TO_GL_ACCOUNTS.DATE_VALID_TO"),
              flex: 1,
              type: 'date',
              minWidth: 230,
              required: true,
              isDefaultValue: true,
              defaultValue: moment('9999-12-30').format("YYYY-MM-DD"),
              renderCell: ({ value }) => {
                if (value) {
                  return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
                }
                return value
              },
              valueParser: (value) => {
                if (value) {
                  return moment(value).format("YYYY-MM-DD")
                }
              }
            },
          ]
        },
        {
          id: "21",
          type: 'table',
          url: backend_url.config_aotype_determination,
          endpoint: [
            { field: "AO_TYPE" },
            { field: "DETERMINATION_EXT_CATEGORY" },
            { field: "DETERMINATION_EXT_CLASS" },
            { field: "DETERMINATION_EXT_SPEND_TYPE" },
            { field: "DETERMINATION_EXT_SUBCLASS" },
          ],
          titleTable: t("Config.TITLE.ACCRUAL_OBJECT_TYPE_DETERMINATION"),
          focusTable: "AO_TYPE",
          idReplace: "",
          selected: false,
          select: 'AO_TYPE',
          value: 'AO_TYPE',
          notistack: true,
          columnSingleSelect: [
            { field: 'AO_TYPE', url: backend_url.config_accrual_object_type, code: "AO_TYPE" },
          ],
          configurationDepend: ['AO_TYPE'],
          // endpointForGet: [
          //   { field: 'BUSINESS_UNITS', name: 'bu_bpx' }
          // ],
          columnsTable: [
            {
              field: 'AO_TYPE',
              headerName: t("Config.ACCRUAL_OBJECT_TYPE_DETERMINATION.AO_TYPE"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'DETERMINATION_EXT_CATEGORY',
              headerName: t("Config.ACCRUAL_OBJECT_TYPE_DETERMINATION.DETERMINATION_EXT_CATEGORY"),
              flex: 1,
              minWidth: 230,
              type: 'singleSelect',
              //required: true,
              renderCell: (params) => {
                const title = t("EXT_CATEGORY", { ns: 'mars_dictionaries' })[params.row.DETERMINATION_EXT_CATEGORY]
                if (!title) {
                  return title
                }
                return `${params.row.DETERMINATION_EXT_CATEGORY}: ${title}`
              },
              valueOptions: Object.entries({ ...t("EXT_CATEGORY", { ns: 'mars_dictionaries' }) })
                .map(it => ({ value: it[0], option: `${it[0]}:  ${it[1]}` })),
            },
            {
              field: 'DETERMINATION_EXT_CLASS',
              headerName: t("Config.ACCRUAL_OBJECT_TYPE_DETERMINATION.DETERMINATION_EXT_CLASS"),
              flex: 1,
              //required: true,
              minWidth: 230,
            },
            {
              field: 'DETERMINATION_EXT_SUBCLASS',
              headerName: t("Config.ACCRUAL_OBJECT_TYPE_DETERMINATION.DETERMINATION_EXT_SUBCLASS"),
              flex: 1,
              //required: true,
              minWidth: 230,
            },
            {
              field: 'DETERMINATION_EXT_SPEND_TYPE',
              headerName: t("Config.ACCRUAL_OBJECT_TYPE_DETERMINATION.DETERMINATION_EXT_SPEND_TYPE"),
              flex: 1,
              minWidth: 230,
              type: 'singleSelect',
              //required: true,
              renderCell: (params) => {
                const title = t("EXT_SPEND_TYPE", { ns: 'mars_dictionaries' })[params.row.DETERMINATION_EXT_SPEND_TYPE];
                if (!title) {
                  return title
                }
                return `${params.row.DETERMINATION_EXT_SPEND_TYPE}: ${title}`
              },
              valueOptions: Object.entries(t("EXT_SPEND_TYPE", { ns: 'mars_dictionaries' }))
                .map(it => ({ value: it[0], option: `${it[0]}:  ${it[1]}` })),
            },
          ]
        },
        {
          id: "11",
          type: 'table',
          url: backend_url.config_accrual_object_type_subtotal_assignment,
          select: 'AO_TYPE',
          value: 'AO_TYPE',
          urrlSelected: backend_url.config_accrual_object_type,
          endpoint: [
            { field: "AO_TYPE" },
            { field: "BP" },
            { field: "BUSINESS_UNIT" },
            { field: "BP_ROLE" },
            { line: 'DATE_VALID_TO' }
          ],
          endpointForGet: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'BUSINESS_UNIT' }
          ],
          titleTable: t("Config.TITLE.ASSIGNMENT_AO_TYPE_BU"),
          focusTable: "BUSINESS_UNIT",
          idReplace: "",
          selected: false,
          notistack: true,
          columnDefined: ['BUSINESS_UNIT', 'AO_TYPE'],
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
            { field: 'AO_TYPE', url: backend_url.config_accrual_object_type, code: "AO_TYPE" },
            { field: 'SUBTOTAL_CODE', url: backend_url.config_subtotals, code: "SUBTOTAL_CODE" },
            { field: 'ACCRUAL_AS_FLEXBASE_SUBTOTAL', url: backend_url.config_subtotals, code: "SUBTOTAL_CODE" }
          ],
          configurationDepend: ['AO_TYPE'],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 170,
            },
            {
              field: 'AO_TYPE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.AO_TYPE"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 150,
            },
            {
              field: 'BP_ROLE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.BP_ROLE"),
              flex: 1,
              required: true,
              type: 'singleSelect',
              valueOptions: ['VE', 'CU', 'IN', '*'],
              minWidth: 150,
            },
            {
              field: 'BP',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.BP"),
              flex: 1,
              minWidth: 130,
              required: true,
            },
            {
              field: 'SUBTOTAL_CODE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.SUBTOTAL_CODE"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 150,
            },
            {
              field: 'USE_RDR',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.USE_RDR"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['Simple_Substitute_Actuals', 'Empty'],
              valueParser: (value) => {
                if (value === 'Empty') {
                  return null
                }
                return value
              },
              minWidth: 150,
            },
            {
              field: 'DATE_RANGE_TYPE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.DATE_RANGE_TYPE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['XTD', 'WTD', 'PTD', 'MTD', 'YTD'],
              required: true,
              minWidth: 150,
            },
            {
              field: 'ACTUALS_INVALID',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.ACTUALS_INVALID"),
              type: "singleSelect",
              isDefaultValue: true,
              defaultValue: 'AGGREGATE_YTD_THEN_FLAT_SPLIT',
              valueOptions: [
                "FLAT_SPLIT",
                "AGGREGATE_YTD_THEN_FLAT_SPLIT",
                "AGGREGATE_XTD_THEN_YTD_THEN_FLAT_SPLIT",
                "AGGREGATE_XTD_THEN_YTD"
              ],
              required: true,
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'DATE_VALID_TO',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.DATE_VALID_TO"),
              flex: 1,
              type: "date",
              minWidth: 150,
              required: true,
              isDefaultValue: true,
              defaultValue: moment('9999-12-30').format("YYYY-MM-DD"),
              renderCell: ({ value }) => {
                if (value) {
                  return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
                }
                return value
              },
              valueParser: (value) => {
                if (value) {
                  return moment(value).format("YYYY-MM-DD")
                }
              }
            },
            {
              field: 'UNCONFIRMED_ACTUALS',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.UNCONFIRMED_ACTUALS"),
              flex: 1,
              type: 'boolean',
              minWidth: 150,
            },
            {
              field: 'PRODUCT_INACTIVE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.PRODUCT_INACTIVE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: [
                '8_STANDARD_ACCRUALS', '2_REMOVE_FROM_RUNPLAN', '4_STOP_AND_KEEP', '6_ACCRUE_ON_ACTUALS', '0_STOP_PROCESSING'
              ],
              isDefaultValue: true,
              defaultValue: '8_STANDARD_ACCRUALS',
              required: true,
              minWidth: 150,
            },
            {
              field: 'BP_INACTIVE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.BP_INACTIVE"),
              type: "singleSelect",
              isDefaultValue: true,
              defaultValue: 'KEEP_ACCRUALS',
              valueOptions: [
                'KEEP_ACCRUALS', 'REMOVE_FROM_CALC', 'STOP_PROCESSING'
              ],
              required: true,
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'PRODUCT_CLOSING',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.PRODUCT_CLOSING"),
              flex: 1,
              type: 'singleSelect',
              defaultValue: '8_STANDARD_ACCRUALS',
              isDefaultValue: true,
              valueOptions: ['8_STANDARD_ACCRUALS', '2_REMOVE_FROM_RUNPLAN', '4_STOP_AND_KEEP', '6_ACCRUE_ON_ACTUALS', '0_STOP_PROCESSING'],
              required: true,
              minWidth: 150,
            },
            {
              field: 'RECALCULATE_BY_STATUS',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.RECALCULATE_BY_STATUS"),
              type: "singleSelect",
              isDefaultValue: true,
              defaultValue: 'KEEP_CLOSING_AND_REMOVE_INACTIVE',
              valueOptions: [
                'KEEP_CLOSING_AND_REMOVE_INACTIVE', 'RECALCULATE_CLOSING_AND_REMOVE_INACTIVE', 'KEEP_CLOSING_AND_INACTIVE', 'RECALCULATE_CLOSING_AND_INACTIVE'

              ],
              required: true,
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'AO_CLOSE_BY_STATUS',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.AO_CLOSE_BY_STATUS"),
              type: "singleSelect",
              isDefaultValue: true,
              defaultValue: 'INCLUDE_CLOSING_EXCLUDE_INACTIVE',
              valueOptions: [
                'INCLUDE_CLOSING_EXCLUDE_INACTIVE', 'INCLUDE_CLOSING_AND_INACTIVE', 'EXCLUDE_CLOSING_AND_INACTIVE'
              ],
              required: true,
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'ACCRUAL_AS_FLEXBASE_SUBTOTAL',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.ACCRUAL_AS_FLEXBASE_SUBTOTAL"),
              flex: 1,
              type: 'singleSelect',
              minWidth: 150,
            },
            {
              field: 'CALCULATION_BUCKETS',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.CALCULATION_BUCKETS"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ["Daily", "Weekly", "No Buckets"],
              minWidth: 150,
              //required: true,
              isDefaultValue: true,
              defaultValue: null,
              valueParser: (value) => {
                if (value === "No Buckets") {
                  return null
                }
                return value
              },
            },
            {
              field: 'ALTERNATIVE_SUBTOTAL_CODE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.ALTERNATIVE_SUBTOTAL_CODE"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'FLEXBASE_RECALCULATE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.FLEXBASE_RECALCULATE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ["Standard_Posting_Only", "Post_and_Save_as_Subtotal", "Post_and_Distribute_Subtotal"],
              minWidth: 150,
            },
            {
              field: 'FLEXBASE_AO_CLOSE',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.FLEXBASE_AO_CLOSE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ["Standard_Posting_Only", "Post_and_Save_as_Subtotal", "Post_and_Distribute_Subtotal"],
              minWidth: 150,
            },
            {
              field: 'GRACE_PERIOD',
              headerName: t("Config.ASSIGNMENT_AO_TYPE_BU.GRACE_PERIOD"),
              flex: 1,
              type: 'number',
              minWidth: 150,
            },
          ]
        },
      ]
    },
    {
      page: "accrual-adjustment",
      children: [
        {
          id: "16",
          type: 'table',
          url: backend_url.config_accrual_adjustment,
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "AO_TYPE" },
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'BUSINESS_UNIT' }
          ],
          titleTable: t("Config.TITLE.ACCRUAL_ADJUSTMENT"),
          focusTable: "BUSINESS_UNIT",
          idReplace: "",
          selected: false,
          select: 'BUSINESS_UNIT',
          value: 'BUSINESS_UNIT',
          notistack: true,
          columnSingleSelect: [
            {
              field: 'BUSINESS_UNIT',
              url: backend_url.config_business_units,
              code: "BUSINESS_UNIT",
              endpoint: [
                { field: 'business_units', name: 'bu_bpx' }
              ]
            },
            { field: 'AO_TYPE', url: backend_url.config_accrual_object_type, code: "AO_TYPE" },
          ],
          configurationDepend: [],
          endpointForGet: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.ACCRUAL_ADJUSTMENT.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'AO_TYPE',
              headerName: t("Config.ACCRUAL_ADJUSTMENT.AO_TYPE"),
              type: 'singleSelect',
              required: true,
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'POSTING_INTERVAL',
              headerName: t("Config.ACCRUAL_ADJUSTMENT.POSTING_INTERVAL"),
              type: 'singleSelect',
              required: true,
              valueOptions: ['W', 'M', 'P'],
              minWidth: 230,
            },
            {
              field: 'APPROVAL_FORM_LANGUAGES',
              headerName: t("Config.ACCRUAL_ADJUSTMENT.APPROVAL_FORM_LANGUAGES"),
              flex: 1,
              type: 'multiSelect',
              required: true,
              valueOptions: ['PL', 'EN'],
              valueParser: (value) => {
                return value.join(',')
              },
              valueFormatted: (value) => {
                return value.split(',')
              },
              minWidth: 230,
            },
            {
              field: 'AMOUNTS_SUM_NOT_ZERO',
              headerName: t("Config.ACCRUAL_ADJUSTMENT.AMOUNTS_SUM_NOT_ZERO"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['E', 'W'],
              minWidth: 230,
            },
          ]
        },
      ]
    },
    {
      page: "integration-parameters",
      children: [
        {
          id: "18",
          type: 'table',
          url: backend_url.config_integration_parameters,
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "PROCESS_ID" },
            { field: "EXTERNAL_SYSTEM" },
            { field: "PARAMETER" },
            { field: "VALUE" },
          ],
          titleTable: t("Config.TITLE.INTEGRATION_PARAMETERS"),
          focusTable: "BUSINESS_UNIT",
          idReplace: "",
          selected: false,
          select: 'BUSINESS_UNIT',
          value: 'BUSINESS_UNIT',
          notistack: true,
          columnSingleSelect: [
            { field: 'BUSINESS_UNIT', url: backend_url.config_business_units, code: "BUSINESS_UNIT" },
          ],
          configurationDepend: [],
          endpointForGet: [
            { field: 'business_units', name: 'bu_bpx' }
          ],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.INTEGRATION_PARAMETERS.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'PROCESS_ID',
              headerName: t("Config.INTEGRATION_PARAMETERS.PROCESS_ID"),
              required: true,
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'EXTERNAL_SYSTEM',
              headerName: t("Config.INTEGRATION_PARAMETERS.EXTERNAL_SYSTEM"),
              type: 'singleSelect',
              required: true,
              valueOptions: ['SAP', 'VISFAB'],
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'PARAMETER',
              headerName: t("Config.INTEGRATION_PARAMETERS.PARAMETER"),
              type: 'singleSelect',
              required: true,
              valueOptions: ['NEGATE', 'SCHEDULE', 'CALENDAR'],
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'VALUE',
              headerName: t("Config.INTEGRATION_PARAMETERS.VALUE"),
              flex: 1,
              required: true,
              minWidth: 230,
            },
          ]
        }
      ]
    },
    {
      page: "algorithms",
      children: [
        {
          id: "12",
          type: 'table',
          url: backend_url.config_algorithms,
          selected: true,
          select: 'ALG_CODE',
          value: 'ALG_CODE',
          endpoint: [
            { field: "ALG_CODE" },
          ],
          titleTable: t("Config.TITLE.ALGORITHMS"),
          focusTable: "ALG_CODE",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [],
          configurationDepend: [],
          columnsTable: [
            {
              field: 'ALG_CODE',
              headerName: t("Config.ALGORITHMS.ALG_CODE"),
              flex: 1,
              //type: 'singleSelect',
              minWidth: 170,
              required: true,
            },
            {
              field: 'ALG_TYPE',
              headerName: t("Config.ALGORITHMS.ALG_TYPE"),
              flex: 1,
              minWidth: 170,
              type: 'singleSelect',
              valueOptions: [
                'DETERMINE_BUSINESS_SEGMENT',
                'SELL_IN_SUBTOTAL',
                'ACCRUAL_CALCULATION',
                'INTERFACE',
                'DETERMINE_MATERIAL_STATUS',
                'DETERMINE_BP_STATUS',
                'PASSWORD_MANAGEMENT'],
              required: true,
            },
            {
              field: 'DESCRIPTION',
              headerName: t("Config.ALGORITHMS.DESCRIPTION"),
              flex: 1,
              minWidth: 170,
              required: true,
            },
            {
              field: 'INOUT_DESC',
              headerName: t("Config.ALGORITHMS.INOUT_DESC"),
              flex: 1,
              minWidth: 170,
              required: true,
            },
            {
              field: 'CODE_SNIPPET_ID',
              headerName: t("Config.ALGORITHMS.CODE_SNIPPET_ID"),
              flex: 1,
              minWidth: 170,
              type: 'number',
              min: 1,
            },
          ]
        },
        {
          id: "13",
          type: 'table',
          url: backend_url.config_algorithms_global_setup,
          selected: false,
          endpoint: [
            { field: "ALG_CODE" },
          ],
          titleTable: t("Config.TITLE.ALGORITHMS_GLOBAL_SETUP"),
          focusTable: "ALG_CODE",
          idReplace: "",
          //notistack: false,
          notistack: true,
          columnSingleSelect: [
            { field: 'ALG_CODE', url: backend_url.config_algorithms, code: "ALG_CODE" },
          ],
          configurationDepend: ['ALG_CODE'],
          columnsTable: [
            {
              field: 'ALG_CODE',
              headerName: t("Config.ALGORITHMS_GLOBAL_SETUP.ALG_CODE"),
              flex: 1,
              minWidth: 170,
              type: 'singleSelect',
              required: true,
            },
            {
              field: 'CONFIG_PAR1',
              headerName: t("Config.ALGORITHMS_GLOBAL_SETUP.CONFIG_PAR1"),
              flex: 1,
              minWidth: 150,
              required: true,
            },
            {
              field: 'CONFIG_PAR2',
              headerName: t("Config.ALGORITHMS_GLOBAL_SETUP.CONFIG_PAR2"),
              flex: 1,
              minWidth: 150,
              required: true,
            },
            {
              field: 'CONFIG_PAR3',
              headerName: t("Config.ALGORITHMS_GLOBAL_SETUP.CONFIG_PAR3"),
              flex: 1,
              minWidth: 150,
              required: true,
            },
          ]
        },
      ]
    },
    {
      page: "unit-of-measure",
      children: [
        {
          id: "15",
          type: 'table',
          url: backend_url.config_unit_of_measure,
          selected: false,
          endpoint: [
            { field: "UOM_CODE" },
          ],
          titleTable: t("Config.TITLE.UNIT_OF_MEASURE"),
          focusTable: "UOM_CODE",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [],
          configurationDepend: [],
          columnsTable: [
            {
              field: 'UOM_CODE',
              headerName: t("Config.UNIT_OF_MEASURE.UOM_CODE"),
              flex: 1,
              minWidth: 170,
              required: true,
            },
            {
              field: 'DIMENSIONALITY',
              headerName: t("Config.UNIT_OF_MEASURE.DIMENSIONALITY"),
              flex: 1,
              minWidth: 150,
              required: true,
            },
            {
              field: 'ISO_CODE',
              headerName: t("Config.UNIT_OF_MEASURE.ISO_CODE"),
              flex: 1,
              minWidth: 150,
              required: true,
            },
            {
              field: 'PINT_CODE',
              headerName: t("Config.UNIT_OF_MEASURE.PINT_CODE"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'COMMERCIAL_CODE',
              headerName: t("Config.UNIT_OF_MEASURE.COMMERCIAL_CODE"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'DESCRIPTION',
              headerName: t("Config.UNIT_OF_MEASURE.DESCRIPTION"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'DECIMALS',
              headerName: t("Config.UNIT_OF_MEASURE.DECIMALS"),
              flex: 1,
              minWidth: 150,
              type: 'number',
              min: 0,
              required: true,
            },
            {
              field: 'NUMERATOR_TO_SI',
              headerName: t("Config.UNIT_OF_MEASURE.NUMERATOR_TO_SI"),
              flex: 1,
              minWidth: 150,
              type: 'number',
              min: 0,
            },
            {
              field: 'DENOMINATOR_TO_SI',
              headerName: t("Config.UNIT_OF_MEASURE.DENOMINATOR_TO_SI"),
              flex: 1,
              minWidth: 150,
              type: 'number',
              min: 0,
            },
          ]
        },
      ]
    },
    {
      page: "highlights",
      children: [
        {
          id: "26",
          type: 'report-parameters',
          url: backend_url.config_report_parameters,
          selected: false,
          select: 'highlights',
          value: 'highlights',
      
          endpointForGet: [
            { line: 'business_unit', name: 'bu_bpx' },
            { line: 'REPORT_CODE', value: "PAT"},
          ],
          endpointForParametr: [
            { line: 'business_unit', name: 'BUSINESS_UNIT' },
            { line: 'REPORT_CODE', name: "REPORT_CODE"},
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "REPORT_CODE" },
            { field: "PARAMETER_NAME" },
          ],
          titleTable: t("Config.TITLE.HIGHLIGHTS"),
          focusTable: "BUSINESS_UNIT",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            {
              field: 'BUSINESS_UNIT',
              url: backend_url.config_business_units,
              code: "BUSINESS_UNIT"
            },
            {
              field: 'Calendar Code',
              url: backend_url.config_calendars,
              code: "CAL_ID",
            },
            {
              field: 'NIV Subtotal Code',
              url: backend_url.config_subtotals,
              code: "SUBTOTAL_CODE",
            },
          ],
          configurationDepend: [],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.REPORT_PARAMETERS.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'REPORT_CODE',
              headerName: t("Config.REPORT_PARAMETERS.REPORT_CODE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['PAT'],
              minWidth: 170,
              required: true,
            },
          ],
          fields:
          [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.REPORT_PARAMETERS.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'REPORT_CODE',
              headerName: t("Config.REPORT_PARAMETERS.REPORT_CODE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['PAT'],
              minWidth: 170,
              required: true,
            },
            {
              field: 'Calendar Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.CALENDAR_CODE"),
              flex: 1,
              type: 'singleSelect',
              minWidth: 150,
              url: backend_url.config_calendars,
              code: "CAL_ID",
            },
            {
              field: 'Object Valid From',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.VALID_FROM"),
              flex: 1,
              //type: 'date',
              minWidth: 170,
              // valueParser: (value) => {
              //   if (value) {
              //     return moment(value).format("YYYY-MM-DD")
              //   }
              // }
            },
            {
              field: 'Object Valid To',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.VALID_TO"),
              flex: 1,
              //type: 'date', 
              minWidth: 170,
              // valueParser: (value) => {
              //   if (value) {
              //     return moment(value).format("YYYY-MM-DD")
              //   }
              // }
            },
            {
              field: 'Timeline Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.TIMELINE_CODE"),
              type: 'singleSelect',
              valueOptions: ['YTDW', 'YTDP'],
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Timeline Name',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.TIMELINE_NAME"),
              flex: 1,
              minWidth: 170,
            },
            {
              field: 'Object Status 1',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.OBJECT_STATUS_1"),
              type: 'multiSelect',
              valueOptionIsObject: true,
              valueOptions: [
                {
                  value: 'NEW',
                  option: 'New'
                },
                {
                  value: 'ACTIVE',
                  option: 'Active'
                },
                {
                  value: 'DELETED',
                  option: 'Deleted'
                },
                {
                  value: 'ENDED',
                  option: 'Ended'
                },
                {
                  value: 'CLOSED',
                  option: 'Closed'
                },
                {
                  value: 'TASK_REQ',
                  option: 'Task Req'
                },
                {
                  value: 'ONHOLD',
                  option: 'On Hold'
                }
              ],
              valueParser: (value) => {
                return value.join(',')
              },
              valueFormatted: (value) => {
                return value.split(',')
              },
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Object Status 2',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.OBJECT_STATUS_2"),
              type: 'multiSelect',
              valueOptionIsObject: true,
              valueOptions: [
                {
                  value: 'SUCCESS',
                  option: 'Success'
                },
                {
                  value: 'PENDING',
                  option: 'Pending'
                },
                {
                  value: 'SENT_TO_ERP',
                  option: 'Sent To Erp'
                },
                {
                  value: 'CANCELLED',
                  option: 'Cancelled'
                },
                {
                  value: 'ERROR',
                  option: 'Error'
                },
                {
                  value: 'PROCESSING',
                  option: 'Processing'
                },
              ],
              valueParser: (value) => {
                return value.join(',')
              },
              valueFormatted: (value) => {
                return value.split(',')
              },
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Object Status 3',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.OBJECT_STATUS_3"),
              type: 'multiSelect',
              valueOptionIsObject: true,
              valueOptions: [
                {
                  value: 'CONFIRMED',
                  option: 'Confirmed'
                },
                {
                  value: 'CONFIRMED_MANUALLY',
                  option: 'Confirmed Manually'
                },
              ],
              valueParser: (value) => {
                return value.join(',')
              },
              valueFormatted: (value) => {
                return value.split(',')
              },
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Accruals',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.ACCRUALS"),
              type: 'boolean',
              isDefaultValue: true,
              defaultValue: 'false',
              flex: 1,
              valueParser: (value) => {
                return String(value)
              },
              valueFormatted: (value) => {
                return Boolean(value === 'true')
              },
              minWidth: 150,
            },
            {
              field: 'Accruals Parameters',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.ACCRUALS_PARAMETER"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Total-OI SubT Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.TOTAL_OI_SUBT_CODE"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'Total-Expenditures Name',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.TOTAL_EXPENDITURES_NAME"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Total-Expenditures Parameters',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.TOTAL_EXPENDITURES_PARAMETER"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Payments',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.PAYMENTS"),
              type: 'boolean',
              isDefaultValue: true,
              defaultValue: 'false',
              valueParser: (value) => {
                return String(value)
              },
              valueFormatted: (value) => {
                return Boolean(value === 'true')
              },
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Payments Name',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.PAYMENTS_NAME"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'Payments Parameters',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.PAYMENTS_PARAMETER"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'SubT 1 Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUB_1_CODE"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 1 Name',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_1_NAME"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 1 Parameters',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_1_PARAMETRS"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 2 Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUB_2_CODE"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 2 Name',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_2_NAME"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 2 Parameters',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_2_PARAMETRS"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 3 Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUB_3_CODE"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 3 Name',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_3_NAME"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 3 Parameters',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_3_PARAMETRS"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 4 Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUB_4_CODE"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 4 Name',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_4_NAME"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 4 Parameters',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_4_PARAMETRS"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 5 Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUB_5_CODE"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 5 Name',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_5_NAME"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'SubT 5 Parameters',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.SUBT_5_PARAMETRS"),
              flex: 1,
              minWidth: 230,
            },
          ],
          sections:
          [
            {
              field: 'General',
              children: ['BUSINESS_UNIT', 'REPORT_CODE'],
            },
            {
              field: 'Calendar/Object Validity',
              children: ['Calendar Code', 'Object Valid From', 'Object Valid To', 'Timeline Code', 'Timeline Name']
            },
            {
              field: 'Object Status',
              children: ['Object Status 1', 'Object Status 2', 'Object Status 3']
            },
            {
              field: 'Accruals',
              children: ['Accruals', 'Accruals Parameters']
            },
            {
              field: 'Totals',
              children: ['Total-OI SubT Code', 'Total-Expenditures Name', 'Total-Expenditures Parameters']
            },
            {
              field: 'Payments',
              children: ['Payments', 'Payments Name', 'Payments Parameters']
            },
            {
              field: 'SubTotals 1',
              children: ['SubT 1 Code', 'SubT 1 Name', 'SubT 1 Parameters']
            },
            {
              field: 'SubTotals 2',
              children: ['SubT 2 Code', 'SubT 2 Name', 'SubT 2 Parameters']
            },
            {
              field: 'SubTotals 3',
              children: ['SubT 3 Code', 'SubT 3 Name', 'SubT 3 Parameters']
            },
            {
              field: 'SubTotals 4',
              children: ['SubT 4 Code', 'SubT 4 Name', 'SubT 4 Parameters']
            },
            {
              field: 'SubTotals 5',
              children: ['SubT 5 Code', 'SubT 5 Name', 'SubT 5 Parameters']
            }
          ]
        },
      ]
    },
    {
      page: "trade-expenditure-monitor",
      children: [
        {
          id: "17",
          type: 'report-parameters',
          url: backend_url.config_report_parameters,
          selected: false,
          select: 'highlights',
          value: 'highlights',
          endpointForGet: [
            { line: 'business_unit', name: 'bu_bpx' },
            { line: 'REPORT_CODE', value: "TEM"},
          ],
          endpointForParametr: [
            { line: 'business_unit', name: 'BUSINESS_UNIT' },
            { line: 'REPORT_CODE', name: "REPORT_CODE"},
          ],
          endpointForPost: [
            { field: 'business_unit', name: 'bu_bpx' }
          ],
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "REPORT_CODE" },
            { field: "PARAMETER_NAME" },
          ],
          titleTable: t("Config.TITLE.TRADE_EXPENDITURE_MONITOR"),
          focusTable: "BUSINESS_UNIT",
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            {
              field: 'BUSINESS_UNIT',
              url: backend_url.config_business_units,
              code: "BUSINESS_UNIT"
            },
            {
              field: 'Calendar Code',
              url: backend_url.config_calendars,
              code: "CAL_ID",
            },
            {
              field: 'NIV Subtotal Code',
              url: backend_url.config_subtotals,
              code: "SUBTOTAL_CODE",
            },
          ],
          configurationDepend: [],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.REPORT_PARAMETERS.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'REPORT_CODE',
              headerName: t("Config.REPORT_PARAMETERS.REPORT_CODE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['TEM'],
              minWidth: 170,
              required: true,
            },
          ],
          fields: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.REPORT_PARAMETERS.BUSINESS_UNIT"),
              flex: 1,
              type: 'singleSelect',
              required: true,
              minWidth: 230,
            },
            {
              field: 'REPORT_CODE',
              headerName: t("Config.REPORT_PARAMETERS.REPORT_CODE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['TEM'],
              minWidth: 170,
              required: true,
            },
            {
              field: 'Calendar Period Type',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.CALENDAR_PERIOD_TYPE"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['Client Period', 'Gregorian Month'],
              minWidth: 230,
            },
            {
              field: 'Calendar Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.CALENDAR_CODE"),
              flex: 1,
              type: 'singleSelect',
              minWidth: 150,
              url: backend_url.config_calendars,
              code: "CAL_ID",
            },
            {
              field: 'Investment Nature Header',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.INVESTMENT_NATURE_HEADER"),
              flex: 1,
              type: 'boolean',
              isDefaultValue: true,
              defaultValue: false,
              valueParser: (value) => {
                return String(value)
              },
              valueFormatted: (value) => {
                return Boolean(value.toLowerCase() === 'true')
              },
              minWidth: 230,
            },
            {
              field: 'Budget Amount Header',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.BUDGET_AMOUNT_HEADER"),
              flex: 1,
              type: 'singleSelect',
              valueOptions: ['All', 'OnlyLS', 'OnlySB'],
              minWidth: 230,
            },
            {
              field: 'NIV Subtotal Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.NIV"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'GSV Subtotal Code',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.GSV"),
              flex: 1,
              minWidth: 230,
            },
            {
              field: 'GSV Percentage Color',
              headerName: t("Config.REPORT_PARAMETERS.PARAMETERS.GSV_COLOR"),
              flex: 1,
              minWidth: 230,
            },
          ],
          sections: [
            {
              field: 'General',
              children: ['BUSINESS_UNIT', 'REPORT_CODE'],
            },
            {
              field: 'TEM',
              children: ['Calendar Period Type', 'Calendar Code', 'Investment Nature Header', 'Budget Amount Header', 'NIV Subtotal Code', 'GSV Subtotal Code', 'GSV Percentage Color']
            },
          ]
        },
      ]
    },
    {
      page: "approval-workflow",
      children: [

        {
          id: "39",
          type: 'table',
          url: backend_url.config_approval_workflow_expiration_time_rules,
          selected: false,
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "FUNCTION" },
          ],
          endpointForPut: [
            { line: 'BUSINESS_UNIT', name: 'BUSINESS_UNIT' },
            { line: 'FUNCTION', name: 'FUNCTION' },
          ],
          endpointForPost: [
            { field: 'BUSINESS_UNIT', name: 'BUSINESS_UNIT' }
          ],
          titleTable: t("Config.TITLE.APPROVAL_WORKFLOW_EXPIRATION_TIME_RULES"),
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            {
              field: 'BUSINESS_UNIT',
              url: backend_url.config_business_units,
              code: "BUSINESS_UNIT"
            },
            { 
              field: 'FUNCTION',
              url: backend_url.config_approval_workflow_filter_values,
              code: "FUNCTION",
              filters: [{field: "filter", value: "FUNCTION"}],
            },
          ],
          endpointForGet: [
            { field: 'BUSINESS_UNIT', name: 'bu_bpx' }
          ],
          configurationDepend: [],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.BUSINESS_UNIT"),
              flex: 1,
              minWidth: 170,
              type: 'singleSelect',
              required: true,
            },
            {
              field: 'FUNCTION',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.FUNCTION"),
              flex: 1,
              minWidth: 150,
              type: 'singleSelect',
              required: true,
            },
            {
              field: 'EXPIRATION_DAYS',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.EXPIRATION_DAYS"),
              flex: 1,
              minWidth: 150,
              required: true,
              headerClassName: "expiration-days-column-header",
              cellClassName: "expiration-days-column-cell",
              type: 'number',
              isDefaultValue: true,
              defaultValue: 30,
              renderCell: (params) => {
                return params.value;
              },
            },
          ]
        },
        {
          id: "19",
          type: 'table',
          url: backend_url.config_approval_workflow,
          selected: false,
          endpoint: [
            { field: "BUSINESS_UNIT" },
            { field: "FUNCTION" },
            { line: 'LEVEL' }
          ],
          endpointForPost: [
            { field: 'BUSINESS_UNIT', name: 'BUSINESS_UNIT' }
          ],
          titleTable: t("Config.TITLE.APPROVAL_WORKFLOW_RULES"),
          idReplace: "",
          notistack: true,
          columnSingleSelect: [
            {
              field: 'BUSINESS_UNIT',
              url: backend_url.config_business_units,
              code: "BUSINESS_UNIT"
            },
          ],
          endpointForGet: [
            { field: 'BUSINESS_UNIT', name: 'bu_bpx' }
          ],
          configurationDepend: [],
          columnsTable: [
            {
              field: 'BUSINESS_UNIT',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.BUSINESS_UNIT"),
              flex: 1,
              minWidth: 170,
              type: 'singleSelect',
              required: true,
            },
            {
              field: 'FUNCTION',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.FUNCTION"),
              flex: 1,
              minWidth: 150,
              type: 'singleSelect',
              valueOptions: ['Accrual Adjustment'],
              required: true,
            },
            {
              field: 'LEVEL',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.LEVEL"),
              flex: 1,
              minWidth: 150,
              required: true,
              type: 'number'
            },
            {
              field: 'NEW',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.NEW"),
              flex: 1,
              minWidth: 150,
              type: 'boolean',
              isDefaultValue: true,
              defaultValue: null,
            },
            {
              field: 'COMPLETED',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.COMPLETED"),
              flex: 1,
              type: 'boolean',
              minWidth: 150,
              isDefaultValue: true,
              defaultValue: false,
            },
            {
              field: 'DOCUMENT_STATUS',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.DOCUMENT_STATUS"),
              flex: 1,
              minWidth: 150,
              type: 'singleSelect',
              valueOptions: ['in Approval', 'Approved', 'Rejected'],
              required: true,
            },
            {
              field: 'MAIN_APPROVERS',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.MAIN_APPROVERS"),
              flex: 1,
              minWidth: 150,
              required: true,
            },
            {
              field: 'BACKUP_APPROVERS',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.BACKUP_APPROVER"),
              flex: 1,
              minWidth: 150,
            },
            {
              field: 'THRESHOLD_FIELD',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.THRESHOLD_FIELD"),
              flex: 1,
              minWidth: 150,
              depended: "THRESHOLD_VALUE",
            },
            {
              field: 'THRESHOLD_VALUE',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.THRESHOLD_VALUE"),
              flex: 1,
              minWidth: 150,
              depended: "THRESHOLD_FIELD"
            },
            {
              field: 'DECISION_EXPIRATION_HOURS',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.DECISION_EXPIRATION_HOURS"),
              flex: 1,
              type: 'number',
              minWidth: 150,
              step: 0.01,
              required: true,
              renderCell: ({ value }) => value && numberFormatter(prefsData.decimal_notation).format(value)
            },
            {
              field: 'INFORMATION_TO',
              headerName: t("Config.APPROVAL_WORKFLOW_RULES.INFORMATION_TO"),
              flex: 1,
              minWidth: 150,
            },
          ]
        },
      ]
    },
    {
      page: 'enterprise-identification',
      children: [
        {
          id: "27",
          type: 'page',
          title:  t("Config.TITLE.ENTERPRISE_IDENTIFICATION")
        },
      ]
    },
  ]

  const changeConfiguration = (name, state) => {
    setConfiguration(() => {
      if (state.checked) {
        return { ...configuration, [name]: state.data }
      }
      return { ...configuration, [name]: null }
    })
  }

  const displayConfig = arrConfigTables.map((item, id) => {
    if (location === item.page) {
      const children = item.children.map(text => {
        switch (text.type) {
          case 'page': 
            return text && (<ConfigurationSeparatePage key={text.id} table={text} business_unit={bu} />)
          case 'report-parameters':
            return text && (<ConfigTableHighlight key={text.id} table={text} configuration={configuration} onHandleClick={changeConfiguration} business_unit={bu} />)
          case 'table': 
            return text && (<ConfigTable key={text.id} table={text} configuration={configuration} onHandleClick={changeConfiguration} business_unit={bu} />)
          case 'assigment':
            return text && (<AssignmentComponent key={text.id} table={text} configuration={configuration} onHandleClick={changeConfiguration} business_unit={bu} />)
        }
      })
      return children
    } else {
      return null
    }

  })


  const objBreadcrumb = [
    { label: t('Breadcrumb.home'), link: '/' },
    { label: t("Config.MENU.MAIN"), link: '/configuration' },
    ...displayCurrentBread
  ]
  return (
    <div className="tableContainer">
      <CustomToolbar bread={objBreadcrumb} title={t("Config.TITLE.MAIN")} />
      <div className="bpx-config-block">

        <div className="bpx-config-left">
          <CollapseMenu items={menuData} />
        </div>

        <div className="bpx-config-right">
          {displayConfig}
        </div>

      </div>

    </div>
  )
}

export default Configuration
